import styled from 'styled-components'


const Footer = () => {
    

    return (
        <footer>
            <FooterWrapper>
                {" "}
            </FooterWrapper>
        </footer>
    )
}

export default Footer;

const FooterWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: lightgray;
` 