import styled from 'styled-components'

const Content = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
`

export default Content
